import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { datadogRum } from '@datadog/browser-rum';
import LoadingLogo from './components/LoadingLogo';
 
const Login = lazy(() => import('./auth/Login'));
const Logout = lazy(() => import('./auth/Logout'));

const MarketingSite = lazy(() => import('./insured/MarketingSite'));
const SMLIntake = lazy(() => import('./insured/SMLIntake'));
const ConfirmInsured = lazy(() => import('./external/agent/ConfirmInsured'));
const BakerMain = lazy(() => import('./external/baker/BakerMain'));

const Home = lazy(() => import('./Home'));
const Settings = lazy(() => import('./Settings'));
const ViewAllAudits = lazy(() => import('./audits/ViewAllAudits'));
const EditAudit = lazy(() => import('./audits/EditAudit'));
const AuditPdf = lazy(() => import('./audits/AuditPdf'));

const ViewAllCarriers = lazy(() => import('./carriers/ViewAllCarriers'));

const ViewInbox = lazy(() => import('./inbox/ViewInbox'));
const ViewUpload = lazy(() => import('./inbox/ViewUpload'));
const ViewScan = lazy(() => import('./inbox/ViewScan'));
const ViewXml = lazy(() => import('./inbox/ViewXml'));

const Manage = lazy(() => import('./manage/Manage'));
//const Reports = lazy(() => import('./reports/Reports'));
const CalView = lazy(() => import('./reports/CalView'));
const MarbleView = lazy(() => import('./reports/MarbleView'));

const Demoes = lazy(() => import('./demo/Demoes'));

const isBrowser = typeof window !== 'undefined';

const isProduction = isBrowser && window.location.href.includes('auditcake.com');
//console.log('isProduction', isProduction);

if (isProduction) {

  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APP_ID,
    clientToken: process.env.REACT_APP_DD_TOKEN,
    site: 'us5.datadoghq.com',
    service: 'ac',
    env: 'prod',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow'
  });
  window.DD_RUM.startSessionReplayRecording();

}

//          <Route path="/admin/audits/:auditCode/view" element={<ViewAudit />}/>
//          <Route exact path="/sml/w/:wauditFSID" element={ <ProvideRetail/>} />


function App() {
 
  return (
    <div className='app'>
      <Suspense fallback={<LoadingLogo/>}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/*" element={ <MarketingSite/>} />
            <Route exact path="/sml" element={ <MarketingSite/>} />
            <Route exact path="/sml/letter" element={ <MarketingSite/>} />
            <Route path="/sml/:auditCode" element={<MarketingSite />}/>
            <Route path="/:auditCode" element={<MarketingSite />}/>
            <Route path="/sml/audit/:auditFSID" element={<SMLIntake />}/>
            <Route path="/sml/audit/:auditFSID/:stepId" element={<SMLIntake />}/>

            <Route exact path="/sml/r/:rauditFSID" element={ <ConfirmInsured/>} />
            <Route exact path="/sml/r/:rauditFSID/:stepId" element={ <ConfirmInsured/>} />

            <Route exact path="/baker" element={ <BakerMain/>} />
            <Route exact path="/baker/:stepId" element={ <BakerMain/>} />

            
            <Route exact path="/admin" element={ <Home/>} />
            <Route exact path="/admin/login" element={ <Login/>} />
            <Route exact path="/admin/logout" element={ <Logout/>} />


            <Route path="/admin/settings" element={<Settings/>} />

            <Route path="/admin/audits" element={<ViewAllAudits/>} />
            <Route path="/admin/audits/:auditCode" element={<ViewAllAudits />}/>
            <Route exact path="/admin/audits/:auditCode/edit" element={<EditAudit />}/>
            <Route exact path="/admin/audits/:auditCode/pdf" element={<AuditPdf />}/>


            <Route path="/admin/carriers" element={<ViewAllCarriers/>} />
            <Route path="/admin/carriers/:carrierId" element={<ViewAllCarriers/>} />

            <Route path="/admin/inbox" element={<ViewInbox/>} />
            <Route path="/admin/inbox/uploads/:batchId" element={<ViewUpload/>} />
            <Route path="/admin/inbox/uploads/:batchId/:uploadId" element={<ViewUpload/>} />
            <Route path="/admin/inbox/scans/:scanId" element={<ViewScan/>} />
            <Route path="/admin/inbox/xmls/:xmlId" element={<ViewXml/>} />


            <Route path="/admin/manage" element={<Manage/>} />
            
            <Route path="/admin/reports" element={<MarbleView/>} />
            <Route path="/admin/reports/calview" element={<CalView/>} />
            <Route path="/admin/reports/marble" element={<MarbleView/>} />

            <Route path="/admin/demo" element={<Demoes/>} />

          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
    
  );
}

export default App;
