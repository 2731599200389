// util helper methods go here... 

import { firestoreMethods } from '../firebase/firestoreMethods';
import { stepValidationMethods } from './stepValidationMethods';
//import { stepAutoMethods } from './stepAutoMethods';
import { utilMethods } from './utilMethods';
//import { commsMethods } from '../util/commsMethods';
//import { functionsMethods } from '../firebase/functionsMethods';

//import stepTypes from './json/stepTypes.json'; // TODO: likely dep
import stepDetails from './json/stepDetails.json';



export const stepMethods = {

  createStep: async (audit, type, nextStepDetails, makeCompleted, args, status) => {
    console.log('CREATE STEP: ', audit, type, nextStepDetails, makeCompleted, args, status)
    const newAuditStep = {
      auditFSID: audit.id,
      auditCode: audit.auditCode,
      dateAdded: new Date(),
      type: type,
      statusHistory: [],
    }

    if (makeCompleted) newAuditStep.dateCompleted = new Date();
    if (args) newAuditStep.args = args;
    if (status) newAuditStep.status = status;

    if (nextStepDetails) {
      for (const [key, value] of Object.entries(nextStepDetails)) {
        newAuditStep[key] = value;
      }
    }

    const augmentedDetails = stepMethods.getStepDetails(type, audit);
    newAuditStep.historyOp = augmentedDetails?.historyOp || '';
    //newAuditStep.displayName = augmentedDetails.name || 'MANUAL';

    // TODO: for now just need historyOp, we'll see later

    //const mergedObject = { ...newAuditStep, ...augmentedDetails };

    //console.log('nas: ', newAuditStep, augmentedDetails);

    // do not attempt to add a step if such a step already exists and is not completed
    const stepExists = await stepMethods.checkExists(audit.id, type);
    if (!stepExists) {
      const stepRef = await firestoreMethods.add("asteps", newAuditStep);
      return stepRef.id;
    } 
    return 'STEP_EXISTS';
    /*
    const stepForAudit = [{op: 'union', name: "steps", value: 
      {type: type, typeDetail: typeDetail, stepFSID: stepRef.id },
    }];
           
    await firestoreMethods.update("audits", audit.id, stepForAudit);
    */
    
  }, 

  setComplete: async ({step, nextStepDetails, allSteps, audit, newName, userId}) => {
    // TODO: add some way to also update the step, so that edits are propagated to next step
    console.log('STEP COMPLETE (step, nextStepDetails, allSteps, audit, newName, userId): ', step, nextStepDetails, allSteps, audit, newName, userId);

    const checkComplete = await stepValidationMethods[step.type](step, audit);
    console.log(checkComplete);
    if (checkComplete.status === '200') {
      if (step.type !== 'NEW') {
        // above if checks that for new audits, don't need to complete anything
        const stepUpdates = [{op: '', name: 'dateCompleted', value: new Date()}]
        stepUpdates.push({op: '', name: 'acUserId', value: userId});
        if (newName) {
          stepUpdates.push({op: '', name: 'historyOp', value: newName});
          stepUpdates.push({op: '', name: 'displayName', value: newName});
        }
        await firestoreMethods.update("asteps", step.id, stepUpdates) 

        //update history TODO: is HISTORY redundant with steps completed?  ITERATING TOWARDS NO, STEPS ARE FUTURE, HISTORY IS PAST + REPORT
        const newHistory = {
            op: newName || step.historyOp,
            text: nextStepDetails?.historyText || '',
            date: new Date(),
            userId: userId || 'System'
        };
        const auditUpdates = [{op: 'union', name: 'history', value: newHistory}]
        await firestoreMethods.update("audits", step.auditFSID, auditUpdates) 
      }
     
      return {status: "200"};
    
    } else {
      return checkComplete;
    }
  },

  bulkCompleteStep: async (bakeryStep, audits, allSteps) => {
    console.log('bulkCompleteStep (bakeryStep, audits, allSteps)', bakeryStep, audits, allSteps);
    for (const a of audits) {
      const auditSteps = allSteps.filter(s => s.auditFSID === a.id);
      //const s = allSteps.filter(step => step.auditFSID === a.id && step.type === bakeryStep)[0];
      //console.log('audit STEPS', auditSteps);
      if (auditSteps.length > 0) {
        await stepMethods.advanceAudit(a, bakeryStep, auditSteps);
      }
    }
  },

  checkExists: async (auditID, stepKey) => {
  //const advanceAudit = async (newA, stepKey) => {
    console.log("CHECK EXISTS (auditID, stepKey,)", auditID, stepKey);
    // TODO: eventually, this needs to happen through server/pub/sub, event driven architecture.
    // get the received info step
    const auditSteps = await stepMethods.getSteps(auditID); 
    console.log("STEPS", auditSteps);
    const checkStep = auditSteps.find(o => o.type === stepKey)
    console.log("found step: ", checkStep)
    
    // mark it as complete, and get the next step
    //const nextStepDetails = null;
    return (checkStep && !checkStep.dateCompleted) ? true : false;
  },

  getSteps: async (auditFSID) => {
    const filter = {name: 'auditFSID', op: '==', value: auditFSID}
    const newData = await firestoreMethods.get("asteps", filter);
    return newData;
  },

  getStepDetails: (stepRef, audit) => {
    //console.log('getStepDetails (stepref, audit): ', stepRef, audit)
    const thisStepDetails = stepDetails[stepRef];
    if (thisStepDetails) {
      thisStepDetails["templateVarsVals"] = {}
      if (thisStepDetails.templateVars) {
        for (const tVar of thisStepDetails.templateVars) {
          thisStepDetails["templateVarsVals"][tVar] = 
            (thisStepDetails?.dateVars?.includes(tVar)) ?
              utilMethods.formatDate(audit[tVar]) :
              audit[tVar];
        }
      } 
    }
    //console.log("thisStepDetails", thisStepDetails);
    return thisStepDetails;
  },

   getStepTypes: () => {
     const steps = Object.keys(stepDetails).sort();
     let retArr = []
     for (const s of steps) {
      retArr.push({
        id: s, 
        name: stepDetails[s].name,
        cat: stepDetails[s].cat,
        icon: stepDetails[s].icon,
      })
     }
     return utilMethods.sortBy(retArr, 'name');
  },
/*
  sortSteps: (arr) => {
    const sortedArray = arr.sort(function(a, b) {
        // currently sorting with most recent step first
        var x = a.dateCompleted;
        var y = b.dateCompleted;
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
     });
    console.log('sorting steps', arr, sortedArray);

    return sortedArray;
  },*/

  sortSteps: (data, field1 = 'dateCompleted', field2 = 'dateStarted') => {
    return data.sort((obj1, obj2) => {
      const hasField1_1 = obj1.hasOwnProperty(field1) && obj1[field1] !== null;
      const hasField1_2 = obj2.hasOwnProperty(field1) && obj2[field1] !== null;

      // Sort by presence of field1 (with values) descending
      if (hasField1_1 !== hasField1_2) {
        return hasField1_2 ? -1 : 1;
      }

      // If both have or lack field1, sort by field2 (ascending)
      if (hasField1_1 && hasField1_2) {
        const date1 = obj1[field2];
        const date2 = obj2[field2];
        return date1 === null ? 1 : (date2 === null ? -1 : new Date(date1) - new Date(date2));
      }

      return 0;
    });
  },

    /*

  getStatusLabel: (audit) => {
    const step = stepMethods.getLastAuditStep(audit.steps);
    if (step) {
      return stepTypes[step.type]["status"] || "N/A";
    }
    return "N/A";
  },

  getLastAuditStep: (arr) => {
    return (arr && arr.length) ? arr[arr.length - 1] : null
  }, 


  getStepTypes: () => {
    return stepTypes;
  },
*/
}